import React from 'react';
import './Loader.scss';

const Loader = ({ text }) => {
  return (
    <div className="loader">
      <span className="loader-icon"></span>
      {text && <p className="loader-text">{text}</p>}
    </div>
  );
};

export default Loader;

import React from 'react';
import { SvgIcon } from '@mui/material';

import common from '../app/App.module.scss';
import styles from './Footer.module.scss';

import { ReactComponent as VKIcon } from '../../icons/vk.svg';
import { ReactComponent as TGIcon } from '../../icons/telegram.svg';

const Footer = () => {
  const thisYear = new Date().getFullYear();

  return (
    <footer className={styles['footer']}>
      <div className={styles['year']}>
        &copy; {thisYear} SkyDNS - Все права защишены
      </div>
      <div className={styles['footer-socials']}>
        <div className={styles['footer-social']}>
          <a
            href="https://vk.com/skydns"
            className={styles['vk']}
            rel="noreferrer"
            aria-label="vk"
          >
            <SvgIcon component={VKIcon} />
            <span className={common['offscreen']}>VK</span>
          </a>
        </div>
        <div className={styles['footer-social']}>
          <a
            href="https://t.me/SkyDNSwrites"
            className={styles['tg']}
            rel="noreferrer"
            aria-label="tg"
          >
            <SvgIcon component={TGIcon} />
            <span className={common['offscreen']}>Telegram</span>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';

const ModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
  const [isShowRemoveModal, setIsShowRemoveModal] = useState(false);
  const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
  const [isShowCollectionModal, setIsShowCollectionModal] = useState(false);

  return (
    <ModalContext.Provider
      value={{
        isShowRemoveModal,
        setIsShowRemoveModal,
        isShowUpdateModal,
        setIsShowUpdateModal,
        isShowCollectionModal,
        setIsShowCollectionModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext;

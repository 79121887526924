import { useDispatch } from 'react-redux';

import { setFilter } from '../../store/usersReducer';

import RadioButtonsGroup from '../radio-button/RadioButtonsGroup';

const UsersAssignedFilter = () => {
  const dispatch = useDispatch();

  const assignHandler = (e) => {
    dispatch(setFilter(e.target.value));
  };

  return (
    <RadioButtonsGroup
      label="Показать:"
      defaultValue="all"
      buttons={[
        { value: 'all', label: 'Все' },
        { value: 'unassigned', label: 'Неназначенные' },
        { value: 'assigned', label: 'Назначенные' },
      ]}
      onChange={assignHandler}
    />
  );
};

export default UsersAssignedFilter;

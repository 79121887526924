import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  create_auth_key: {},
  auth_key: {},
  new_user: [],
  filteredUsersFromApi: [],
  foundUsers: [],
  loading: false,
  error: false,
  authKeyError: false,
  totalPagesCount: 1,
  initialUsersSize: 0,

  filter: 'all',
  searchQuery: '',
  users_list: [],
  usersAssignedToCollections: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    // TODO: update auth key with action.payload maybe
    createdAuthKey(state, authKey) {
      state.create_auth_key = authKey;
    },
    addedUser(state, action) {
      state.new_user = action.payload;
    },
    getAuthKey(state) {
      state.loading = true;
    },
    getAuthKeySuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.auth_key = action.payload;
    },
    getAuthKeyFailure(state) {
      state.loading = false;
      state.authKeyError = true;
      state.auth_key = {};
    },
    getUsersList(state) {
      state.loading = true;
      state.error = false;
      state.users_list = [];
    },
    getUsersListSuccess(state, action) {
      state.loading = false;
      state.error = false;
      state.users_list = action.payload;
      state.initialUsersSize = action.payload.length;
    },
    getUsersListFailure(state) {
      state.loading = false;
      state.error = true;
      state.users_list = [];
    },
    foundUsers(state, action) {
      const searchQuery = action.payload.toLocaleLowerCase();

      const foundUsers = state.users_list.filter((item) => {
        return (
          item.name.toLocaleLowerCase().includes(searchQuery) ||
          item.last_seen.toLocaleLowerCase().includes(searchQuery)
        );
      });

      if (searchQuery.length > 0) {
        if (foundUsers.length === 0) {
          const notFound = [
            {
              domain: 0,
              hostname: '',
              id: 0,
              last_seen: '',
              name: 'Пользователь не найден',
              os_info: '',
              profile_id: 0,
              token: 0,
            },
          ];
          state.foundUsers = notFound;
        } else {
          state.foundUsers = foundUsers;
        }
      } else {
        state.foundUsers = [];
      }
    },
    updatedTotalPagesCount(state, action) {
      state.totalPagesCount = action.payload;
    },
    reducedUsersToTheirInitialSize(state) {
      state.users_list = state.users_list.slice(0, state.initialUsersSize);
    },
    setFilteredUsersFromApi(state, action) {
      state.filteredUsersFromApi = action.payload;
    },
  },
});

export const {
  setFilter,
  setSearchQuery,
  createdAuthKey,
  addedUser,
  getAuthKey,
  getAuthKeySuccess,
  getAuthKeyFailure,
  getUsersList,
  getUsersListSuccess,
  getUsersListFailure,
  foundUsers,
  updatedTotalPagesCount,
  updatedUsersOnScroll,
  reducedUsersToTheirInitialSize,
  setFilteredUsersFromApi,
  triggerUsersLoadingOnScroll,
} = usersSlice.actions;
export default usersSlice.reducer;

// export const selectFilteredUsers = (state) => {
//   return filterUsers(state.users.users, state.users.filter, state.users.searchQuery)
// };

import { useState, useEffect } from 'react';

const useValidateDomain = () => {
  const [name, setName] = useState('');
  const [nameIsBlur, setNameIsBlur] = useState(false);
  const [nameError, setNameError] = useState('Domain name field is required');
  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsSuccess, setFormIsSuccess] = useState(false);
  const [domainData, setDomainData] = useState({});

  useEffect(() => {
    nameError ? setFormIsValid(false) : setFormIsValid(true);
  }, [nameError]);

  const nameHandler = (e) => {
    setName(e.target.value);

    // eslint-disable-next-line
    const regex =
      /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9\-\.]){1,61}(?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/i;

    if (!e.target.value) {
      setNameError('Checked domain is not provided');
    } else if (!regex.test(String(e.target.value).toLowerCase())) {
      setNameError('Domain is not a valid');
    } else {
      setNameError('');
      setDomainData({ ...domainData, name: e.target.value });
    }
  };

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'name':
        setNameIsBlur(true);
        break;
      default:
        break;
    }
  };

  return {
    name,
    nameIsBlur,
    nameError,
    formIsValid,
    formIsSuccess,
    domainData,
    nameHandler,
    blurHandler,
    setName,
    setNameError,
    setFormIsValid,
    setFormIsSuccess,
  };
};

export default useValidateDomain;

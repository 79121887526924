import React from 'react';
import { Stack, Paper } from '@mui/material';

import UsersList from './UsersList';
import Search from '../UI/search/Search';
import UsersAssignedFilter from './UsersAssignedFilter';

import { setSearchQuery } from '../../store/usersReducer';

import styles from './Users.module.scss';

const Users = () => {
  return (
    <Paper>
      <Stack
        component="form"
        sx={{
          gap: 2,
        }}
        noValidate
        className={styles['users-control']}
      >
        <Search placeholder="Найти пользователя" cb={setSearchQuery} />
        <UsersAssignedFilter />
      </Stack>
      <UsersList />
    </Paper>
  );
};

export default Users;

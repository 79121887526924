/**
 * @typedef {object} User
 * @property {number} id - the id of the user
 * @property {string} name - The name of the user.
 */

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
} from '@mui/material';

import useSortable from '../../hooks/useSortable';
import usePagination from '../../hooks/usePagination';
import UsersListItem from './UsersListItem';
import FetchError from '../UI/fetch-error/FetchError';
import Loader from '../UI/loader/Loader';
import { TableHeadCell } from '../table-cells/TableCells';
import Tooltip from '../tooltip/Tooltip';
import Paginator from '../UI/paginator/Paginator';

import { uid } from '../../uid';

import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { ReactComponent as SortIcon } from '../../icons/sort.svg';

import styles from './Users.module.scss';

function selectCollections(state) {
  return state.collections.collections_list;
}

const selectUsersAssignedToCollections = createSelector(
  selectCollections,
  (collections) => {
    return collections.reduce((users, collection) => {
      users.push(...collection.ad_users);

      return users;
    }, []);
  }
);

/**
 * @param {User[]} usersList
 * @param {string} searchQuery
 * @returns {User[]}
 */
const filterUsers = (usersList, searchQuery) => {
  const lowerCaseSearchQuery = searchQuery.toLowerCase();

  return usersList.filter((user) => {
    return user.name.toLowerCase().includes(lowerCaseSearchQuery);
  });
};
/**
 * @param {User[]} usersList
 * @param {'assigned' | 'unassigned' | 'all'} filter
 * @param {User[]} usersAssignedToCollections
 */
const findAssignedUsers = (usersList, filter, usersAssignedToCollections) => {
  return usersList.filter((user) => {
    switch (filter) {
      case 'assigned':
        return usersAssignedToCollections.includes(user.id);
      case 'unassigned':
        return !usersAssignedToCollections.includes(user.id);
      default:
        return true;
    }
  });
};

const UsersList = () => {
  const { error, loading } = useSelector((state) => state.users);
  const { users_list, searchQuery, filter } = useSelector(
    (state) => state.users
  );
  const collections = useSelector(
    (state) => state.collections.collections_list
  );
  const usersAssignedToCollections = useSelector((state) =>
    selectUsersAssignedToCollections(state)
  );

  let { result, requestSort } = useSortable(users_list);

  // TODO: it's not a good idea to assign filtering results like that:
  result = findAssignedUsers(result, filter, usersAssignedToCollections);

  if (searchQuery.length > 0) {
    // TODO: it's not a good idea to assign filtering results like that:
    result = filterUsers(result, searchQuery);
  }

  const ITEMS_PER_PAGE = 5;
  const {
    firstContentIndex,
    lastContentIndex,
    totalPages,
    page,
    gaps,
    setPage,
    nextPage,
    prevPage,
  } = usePagination({
    contentPerPage: ITEMS_PER_PAGE,
    count: result.length,
  });

  useEffect(() => {
    if (searchQuery.length > 0) {
      setPage(1);
    }

    // TODO: figure out how to avoid disabling eslint
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const lastSeenHandler = () => {
    requestSort('last_seen');
  };

  if (loading) {
    return <Loader text="Please wait..." />;
  }

  if (error) {
    return <FetchError />;
  }

  if (!result && !loading && !error) {
    return <p className={styles['users-list-empty']}>No Users yet</p>;
  }

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="users list">
          <TableHead>
            <TableRow>
              <TableHeadCell>
                <Typography variant="h2" component="span">
                  Пользователи
                </Typography>
              </TableHeadCell>
              <TableHeadCell align="left">
                <Typography variant="h2" component="span">
                  Коллекции
                </Typography>
              </TableHeadCell>
              <TableHeadCell align="left">
                <Typography variant="h2" component="span">
                  Профиль
                </Typography>
                <Tooltip
                  arrow
                  classes={{
                    tooltip: styles['MuiTooltip-tooltip'],
                    arrow: styles['MuiTooltip-arrow'],
                  }}
                  title={
                    <Typography
                      variant="caption"
                      component="span"
                      color="info.main"
                    >
                      Активная политика указана из коллекции с наивысшим
                      приоритетом. Вы можете изменить приоритет коллекции на
                      вкладке Коллекции
                    </Typography>
                  }
                >
                  <InfoIcon />
                </Tooltip>
              </TableHeadCell>
              <TableHeadCell align="left">
                <Typography variant="h2" component="span">
                  ПК
                </Typography>
              </TableHeadCell>
              <TableHeadCell align="left">
                <Typography variant="h2" component="span">
                  ОС
                </Typography>
              </TableHeadCell>
              <TableHeadCell align="center">
                <Box
                  component="span"
                  sx={{ cursor: 'default' }}
                  onClick={lastSeenHandler}
                >
                  <SortIcon style={{ marginLeft: 0, marginRight: 4 }} />
                  <Typography
                    variant="h2"
                    component="span"
                    sx={{ verticalAlign: 'middle' }}
                  >
                    Последний визит
                  </Typography>
                </Box>
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.slice(firstContentIndex, lastContentIndex).map((item) => (
              <UsersListItem
                key={item.id}
                id={item.id}
                userName={item.name}
                collections={collections.map((collection) => {
                  if (collection.ad_users.includes(item.id)) {
                    return (
                      <Typography key={uid()} component="p" sx={{ pb: 1 }}>
                        {collection.name}
                      </Typography>
                    );
                  }

                  return null;
                })}
                policy={item.profile_id}
                pc={item.hostname}
                os={item.os_info}
                last_seen={item.last_seen.slice(0, 10)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {result.length > ITEMS_PER_PAGE && (
        <Paginator
          nextPage={nextPage}
          prevPage={prevPage}
          page={page}
          gaps={gaps}
          setPage={setPage}
          totalPages={totalPages}
        />
      )}
    </React.Fragment>
  );
};

export default UsersList;

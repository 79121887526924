import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, InputBase, IconButton, Box } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const Search = ({ placeholder, cb }) => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const searchHandler = (e) => {
    setSearch(e.target.value);
    dispatch(cb(e.target.value));
  };

  const clearField = () => {
    setSearch('');
    dispatch(cb(''));
  };

  return (
    <Grid
      container
      sx={{
        pt: '4px',
        pb: '4px',
        borderTop: '1px solid #ebeff2',
        borderBottom: '1px solid #ebeff2',
      }}
    >
      <Box sx={{ p: 1 }}>
        <SearchIcon
          aria-label="search"
          color="info"
          sx={{ verticalAlign: 'middle' }}
        />
      </Box>
      <InputBase
        id="search-field"
        placeholder={placeholder}
        value={search}
        onChange={searchHandler}
        sx={{ flex: '1 0' }}
      />

      <IconButton onClick={clearField} color="info">
        <CloseIcon aria-label="clear" />
      </IconButton>
    </Grid>
  );
};

export default Search;
